body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
a {
  color: #fff;
}
a:hover {
  color: #fff;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner1 {
  width: 100%;
  height: 100vh;
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner1-wrapper,
.banner1 .banner-anim {
  height: 100%;
}
.banner1 .queue-anim-leaving {
  position: relative !important;
}
.banner1 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner1 .bg0 {
  background: url("https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg") center;
}
.banner1 .bg1 {
  background: url("https://zos.alipayobjects.com/rmsportal/xHxWkcvaIcuAdQl.jpg") center;
}
.banner1 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner1 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner1 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner1 .banner1-text-wrapper {
  display: block;
  position: relative;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  color: #fff;
  width: 550px;
  text-align: center;
}
.banner1 .banner1-title {
  width: 350px;
  left: 30px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner1 .banner1-content {
  margin-bottom: 20px;
  word-wrap: break-word;
}
.banner1 .banner1-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner1 .banner1-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner1 .banner1-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner1 .banner1-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner1 .banner1-button.queue-anim-leaving {
  width: auto;
}
.banner1 .banner1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .banner1 .banner1-text-wrapper {
    width: 90%;
  }
  .banner1 .banner1-text-wrapper .banner1-title {
    width: 90%;
    left: 0;
  }
  .banner1 .bg {
    background-attachment: inherit;
  }
}
.content4-wrapper {
  min-height: 720px;
  background: #fafafa;
}
.content4-wrapper .content4 {
  height: 100%;
  overflow: hidden;
}
.content4-wrapper .content4-video {
  border-radius: 4px;
  overflow: hidden;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.content4-wrapper .content4-video video {
  display: block;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .content4-wrapper {
    min-height: 350px;
  }
  .content4-wrapper .content4 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content4-wrapper .content4-video {
    top: 15%;
    background: url("https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg") no-repeat center;
    background-size: cover;
  }
}
.content13-wrapper {
  min-height: 380px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 96px 0;
}
.content13-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content13-wrapper .title-content {
  line-height: 32px;
}
@media screen and (max-width: 767px) {
  .content13-wrapper {
    padding-bottom: 0;
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 200px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  color: #404040;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px auto 8px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 1440px;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
#Nav3_0 .l7ku9zf1c0j-editor_css {
  color: #feffff;
}
#Nav3_0 .ant-menu-item > .header3-item-block > .l7kudy1cv7k-editor_css {
  color: #ffffff;
}
#Nav3_0 .ant-menu-submenu-title > .header3-item-block > .l7kuas5y0r-editor_css {
  color: #ffffff;
}
#Nav3_0 .ant-menu-item > .header3-item-block > .l7kue8d5pna-editor_css {
  color: #ffffff;
}
#Nav3_0 .ant-menu-item > .header3-item-block > .l7kuea1xa6-editor_css {
  color: #ffffff;
  border-bottom-color: #ffffff;
}
#Nav3_0 .header3-menu > .ant-menu > .l7kuf5zrojq-editor_css {
  border-bottom-color: initial;
  border-bottom-width: 0px;
}
#Nav3_0 .header3-menu > .ant-menu > .l7kugn8i23-editor_css {
  border-bottom-color: initial;
  border-bottom-width: 0px;
}
#Nav3_0 .header3-menu > .ant-menu > .l7kuf9ouqt-editor_css {
  border-bottom-color: initial;
  background-clip: padding-box;
  border-bottom-width: 0px;
}
#Nav3_0 .header3-menu > .ant-menu > .l7kuf9ouqt-editor_css:hover {
  border-bottom-color: #ffffff;
}
#Nav3_0 .header3-menu > .ant-menu > .l7kuecsjmn-editor_css {
  border-bottom-color: initial;
  border-bottom-width: 0px;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .l7ky58e18et-editor_css {
  left: 0px;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .l7ky5i69v4q-editor_css {
  margin: 0 0 0px;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .l7ky6ipyk1-editor_css {
  border-width: 0px;
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .l7ky5oqdsll-editor_css {
  border-width: 0px;
}
#Nav3_0.l7kuc1ms3b-editor_css {
  background-color: #856e3f;
}
#Footer1_1 .footer1 > .ant-row > .l7kzuoo3j8m-editor_css {
  color: #fbfbfb;
  width: 300px;
}
#Footer1_1.l7l01itst6-editor_css {
  background-color: #856e3f;
}
#Footer1_1 .ant-col > div > .l7kzuthdtt-editor_css {
  color: #fbfbfb;
  width: 500px;
}
@media screen and (max-width: 767px) {
  #Footer1_1 .ant-col > div > .l7kzuthdtt-editor_css {
    text-align: left;
    width: 500px;
  }
}
#Footer1_1 .ant-col > div > .l7kzuweiuji-editor_css {
  color: #fbfbfb;
  width: 500px;
}
@media screen and (max-width: 767px) {
  #Footer1_1 .ant-col > div > .l7kzuweiuji-editor_css {
    text-align: left;
    width: 500px;
  }
}
#Footer1_1 .ant-col > div > .l7kzuz5iz8s-editor_css {
  color: #fbfbfb;
  width: 500px;
}
@media screen and (max-width: 767px) {
  #Footer1_1 .ant-col > div > .l7kzuz5iz8s-editor_css {
    text-align: left;
    width: 500px;
  }
}
#Footer1_1 .ant-col > div > .l7kzv14b6cr-editor_css {
  color: #fbfbfb;
  width: 500px;
}
@media screen and (max-width: 767px) {
  #Footer1_1 .ant-col > div > .l7kzv14b6cr-editor_css {
    text-align: left;
    width: 500px;
  }
}
#Footer1_1 .ant-row > .ant-col > .l7kzv31qnip-editor_css {
  color: #fbfbfb;
}
@media screen and (max-width: 767px) {
  #Footer1_1 .ant-row > .ant-col > .l7kzv31qnip-editor_css {
    text-align: left;
  }
}
#Nav3_0 .ant-menu-item > .header3-item-block > .l7kuas5y0r-editor_css {
  color: #fbfbfb;
}
#Banner1_0 .banner1-wrapper > .banner-anim > .l7m53wlopqg-editor_css {
  display: block;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .l7k7xzcr4np-editor_css {
  font-family: 'Arial Black';
  top: 0px;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim > .banner-anim-elem > .l7k7xzcr4np-editor_css {
    font-family: 'Arial Black';
    height: 0px;
    top: 0px;
  }
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .l7k84ddwvlt-editor_css {
  background-clip: padding-box;
  top: 0px;
  border-width: 0px;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .l7k84ddwvlt-editor_css {
    display: block;
    top: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
  }
}
#Banner1_0 .banner1-wrapper > .banner-anim > .l7m550qcnkm-editor_css {
  justify-content: center;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner1-wrapper > .banner-anim > .l7m550qcnkm-editor_css {
    display: flex;
    justify-content: center;
  }
}
#Banner1_0.l7m579sfe5-editor_css {
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  #Banner1_0.l7m579sfe5-editor_css {
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
}
#Content4_0 .home-page > div > .l7kyfttedr6-editor_css {
  align-items: flex-start;
  width: 100%;
}
@media screen and (max-width: 767px) {
  #Content4_0 .home-page > div > .l7kyfttedr6-editor_css {
    align-items: flex-start;
    width: 100%;
  }
}
#Content13_0 .title-wrapper > .l7om2xek87-editor_css {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 100px;
  height: 300300px;
}
#Content13_0 .title-wrapper > .l7om6596wy-editor_css {
  display: flex;
  justify-content: space-around;
  height: 70%;
}
#Content13_0 .title-wrapper > .l7omt2z2nah-editor_css {
  display: flex;
  justify-content: space-between;
  width: 50%;
  height: 300px;
  background-image: url('https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/banner3.png');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: center top;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .l7onn77z5qm-editor_css {
    display: none;
  }
}
#Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .l7kujh5cnkm-editor_css {
  left: 0px;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .l7kujh5cnkm-editor_css {
    display: none;
    margin: 0px 0px;
    padding: 0px 0px;
  }
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim-elem > .banner1-text-wrapper > .l7onoch9azu-editor_css {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim > .banner-anim-elem > .l7onofqj1bs-editor_css {
    display: none;
  }
}
#Content13_0.l7om0oqhooh-editor_css {
  text-align: center;
  height: 100%;
  background-image: url('');
  padding: 20px 0px 0;
}
#Content13_1.l7ooir9alqs-editor_css {
  background-image: url('');
  padding: 0px 0px;
}
#Content13_2.l7oou3ijo5-editor_css {
  background-image: url('');
  padding: 0px 0px;
}
#Teams1_2 .l7op4inssm-editor_css {
  padding: 0 0;
}
#Teams1_2 .ant-row > .ant-col > .l7op70euv6m-editor_css {
  font-size: 16px;
  margin: 0px auto;
}
#Teams1_2 .ant-row > .ant-col > .l7op6sng759-editor_css {
  font-size: 16px;
  margin: 0px auto;
}
#Teams1_2 .ant-row > .ant-col > .l7op6mi1wg6-editor_css {
  font-size: 16px;
  margin: 0px auto;
}
#Teams1_2 .ant-row > .ant-col > .l7op7e4yfzk-editor_css {
  text-align: left;
  margin: 0px auto;
}
#Teams1_2 .ant-row > .ant-col > .l7op8b5kbi6-editor_css {
  text-align: left;
  margin: 0px auto;
}
#Teams1_2 .ant-row > .ant-col > .l7op85weotj-editor_css {
  text-align: left;
  margin: 0px auto;
}
@media screen and (max-width: 767px) {
  #Content13_0 .title-wrapper > .l7opgptg4r-editor_css {
    font-size: 12px;
  }
}
#Nav3_0 .home-page > .l7ku9pm04b-editor_css {
  width: 60px;
}
@media screen and (max-width: 767px) {
  #Nav3_0 .home-page > .l7ku9pm04b-editor_css {
    width: 40px;
  }
}
#Banner1_0 .banner-anim > .banner-anim-elem > .l7k753lsy4a-editor_css {
  cursor: pointer;
  justify-content: space-around;
  color: #fafafa;
  position: static;
  background-image: url('https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruiluqianqiu6.jpg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-clip: border-box;
  transition: -webkit-transform 0s ease 0s;
  transition: transform 0s ease 0s;
  transition: transform 0s ease 0s, -webkit-transform 0s ease 0s;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim > .banner-anim-elem > .l7k753lsy4a-editor_css {
    cursor: pointer;
    justify-content: center;
    color: #fafafa;
    position: static;
    float: left;
    background-image: url('https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruiluqianqiu6.jpg');
    transition: -webkit-transform 0s ease 0s;
    transition: transform 0s ease 0s;
    transition: transform 0s ease 0s, -webkit-transform 0s ease 0s;
  }
}
#Banner1_0 .banner-anim > .banner-anim-elem > .l7k75uusgmc-editor_css {
  cursor: pointer;
  align-items: flex-start;
  position: static;
  background-image: url('https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruiluqianqiu5.jpg');
  background-position: center top;
  transition: -webkit-transform 0s ease 0s;
  transition: transform 0s ease 0s;
  transition: transform 0s ease 0s, -webkit-transform 0s ease 0s;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim > .banner-anim-elem > .l7k75uusgmc-editor_css {
    cursor: pointer;
    align-items: flex-start;
    position: static;
    background-image: url('https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruiluqianqiu5.jpg');
    transition: -webkit-transform 0s ease 0s;
    transition: transform 0s ease 0s;
    transition: transform 0s ease 0s, -webkit-transform 0s ease 0s;
  }
}
#Banner1_0 .banner-anim > .banner-anim-elem > .l7k76a0ko3-editor_css {
  cursor: pointer;
  align-items: flex-start;
  background-image: url('https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruiluqianqiu4.jpg');
  background-position: center bottom;
  transition: -webkit-transform 0s ease 0s;
  transition: transform 0s ease 0s;
  transition: transform 0s ease 0s, -webkit-transform 0s ease 0s;
}
@media screen and (max-width: 767px) {
  #Banner1_0 .banner-anim > .banner-anim-elem > .l7k76a0ko3-editor_css {
    cursor: pointer;
    align-items: flex-start;
    background-image: url('https://phoenixdew.oss-cn-beijing.aliyuncs.com/assets/ruiluqianqiu4.jpg');
    transition: -webkit-transform 0s ease 0s;
    transition: transform 0s ease 0s;
    transition: transform 0s ease 0s, -webkit-transform 0s ease 0s;
  }
}
#Teams1_2 .home-page > .title-wrapper > .l7opoqb0iy6-editor_css {
  margin: 0px 0 0;
}
#Teams1_2 .home-page > .l7opozl91ll-editor_css {
  margin: 0px auto 0;
}
#Content13_0 .title-wrapper > .l7oon0ojzg-editor_css {
  padding: 20px 0 0;
}
@media screen and (max-width: 767px) {
  #Content13_0 .title-wrapper > .l7oon0ojzg-editor_css {
    font-size: 12px;
    padding: 20px 0px 0px;
  }
}
#Content4_0 .home-page > .l7optvf4blc-editor_css {
  margin: 0px auto 0;
}
#Content13_1 .title-wrapper > .l7ooiz0izy-editor_css {
  display: flex;
  justify-content: space-around;
  height: 300px;
  padding: 20px 20px 0px;
}
#Content13_2 .title-wrapper > .l7ooud2jqd8-editor_css {
  display: flex;
  justify-content: space-around;
  height: 300px;
  padding: 20px 20px 0px;
}
#Content4_0 .home-page > .l7opudkjei-editor_css > .l7kyfttedr6-editor_css {
  padding: 10px;
}
#Content13_0 .title-wrapper > .l7onawccg2-editor_css {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 300px;
  padding: 20px 20px 0px;
}
#Content13_0 .title-wrapper > .l7oqbdbb1cq-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
#Content13_0 .title-wrapper > .l7oqbwwy43r-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
#Content13_0 .title-wrapper > .l7oqci4lj-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
#Content13_1 .title-wrapper > .l7oqfn38nkm-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
#Content13_1 .title-wrapper > .l7oqg104i0b-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
#Content13_1 .title-wrapper > .l7oqg8gn93t-editor_css {
  padding: 0 0px 0px;
}
#Content13_1 .title-wrapper > .l7oqftezgfo-editor_css {
  padding: 0 0px 0px;
}
#Content13_2 .title-wrapper > .l7op2z2rpsn-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
@media screen and (max-width: 767px) {
  #Content13_2 .title-wrapper > .l7op2z2rpsn-editor_css {
    font-size: 12px;
    padding: 20px 0px 0px;
  }
}
#Content13_1 .title-wrapper > .l7oqmh7qr7l-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
#Content13_1 .title-wrapper > .l7oqfgqg6b6-editor_css {
  padding: 0px 0px;
}
#Content4_0 .home-page > .title-wrapper > .l7kyrnu5d3r-editor_css {
  font-size: 24px;
  clear: right;
  padding: 20px 0px 10px;
}
@media screen and (max-width: 767px) {
  #Content4_0 .home-page > .title-wrapper > .l7kyrnu5d3r-editor_css {
    font-size: 24px;
  }
}
#Teams1_3 .home-page > .title-wrapper > .l7oqw6kilua-editor_css {
  margin: 0px 0 0;
}
#Teams1_3 .home-page > .l7oqwl22th-editor_css {
  margin: 0px auto 0;
}
#Teams1_3 .l7oqwj4o1og-editor_css {
  padding: 0 0;
}
#Teams1_3 div > .ant-row > .l7oqygnhgna-editor_css {
  margin: 0 0;
}
#Teams1_3 div > .ant-row > .l7oqyromixj-editor_css {
  margin: 0 0;
}
#Teams1_3 div > .ant-row > .l7oqxid56hu-editor_css {
  margin: 0 0;
}
#Teams1_3 .home-page > div > .l7oqy7x5vsg-editor_css {
  padding: 0 0px;
}
#Content13_2 .title-wrapper > .l7op2s0msla-editor_css {
  font-size: 24px;
  margin: 0px 0;
  padding: 20px 0px 0px;
}
@media screen and (max-width: 767px) {
  #Content13_2 .title-wrapper > .l7op2s0msla-editor_css {
    font-size: 24px;
    padding: 20px 0px 0px;
  }
}
#Content13_0 .title-wrapper > .l7ooet00i88-editor_css {
  font-size: 24px;
  margin: 0px 0px 20px;
}
@media screen and (max-width: 767px) {
  #Content13_0 .title-wrapper > .l7ooet00i88-editor_css {
    font-size: 24px;
    margin: 0px 0px;
  }
}
#Content13_3 .title-wrapper > .l7or6dke1pe-editor_css {
  font-size: 24px;
  margin: 0px 0 20px;
}
#Content13_3 .title-wrapper > .l7orhbvyxqp-editor_css {
  font-weight: bold;
}
#Content13_3 .title-wrapper > .l7orhh174e7-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
#Content13_3 .title-wrapper > .l7ori2i6ze6-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
#Content13_3 .title-wrapper > .l7or7ig6o56-editor_css {
  font-size: 24px;
  margin: 0px 0px 20px;
  padding: 20px 0px 0px;
}
#Content13_3 .title-wrapper > .l7orkv5ipwg-editor_css {
  font-weight: bold;
}
#Content4_0 .l7l02u3cwsi-editor_css {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0px 0px 0;
}
@media screen and (max-width: 767px) {
  #Content4_0 .l7l02u3cwsi-editor_css {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
#Content4_0 .home-page > .l7oqt3vxfn-editor_css {
  padding: 0 0 20px;
}
#Teams1_2 .home-page > div > .l7oqqz3o73-editor_css {
  margin: 0px;
  padding: 0px 0px;
}
#Teams1_2 div > .ant-row > .l7orts4livn-editor_css {
  margin: 0 0;
}
#Teams1_2 div > .ant-row > .l7oruayoj8m-editor_css {
  margin: 0 0;
}
#Teams1_3.l7oqwz4wtn-editor_css {
  min-height: 0px;
  background-color: #fbfbfb;
}
#Teams1_2.l7oqx4rtc9p-editor_css {
  min-height: 0px;
}
#Content13_4 .title-wrapper > .l7pt3hetyfo-editor_css {
  font-size: 24px;
  margin: 0px 0;
}
#Content13_4 .title-wrapper > .l7pt4y9qr8-editor_css {
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: scroll;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
#Content13_4 .title-wrapper > .l7pt95slngt-editor_css {
  font-weight: bold;
  padding: 10px 0 0;
}
#Content13_4 .title-wrapper > .l7pt9q0zm8i-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
#Content13_4 .title-wrapper > .l7pt9to3r8k-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
#Content13_0 .title-wrapper > .l7oqce1fu16-editor_css {
  padding: 0 0px 0px;
}
@media screen and (max-width: 767px) {
  #Content13_0 .title-wrapper > .l7oqce1fu16-editor_css {
    padding: 0px 0px;
  }
}
#Content13_1 .title-wrapper > .l7oojezg8ki-editor_css {
  font-weight: bold;
  padding: 10px 0px 0px;
}
@media screen and (max-width: 767px) {
  #Content13_1 .title-wrapper > .l7oojezg8ki-editor_css {
    font-weight: bold;
    padding: 10px 0px 0px;
  }
}
#Content13_0 .l7om4o6vrql-editor_css {
  margin: 0px auto;
}
@media screen and (max-width: 767px) {
  #Content13_0 .l7om4o6vrql-editor_css {
    margin: 0px auto;
    padding: 0 10px;
  }
}
#Content13_0 .title-wrapper > .l7oqcl9n2it-editor_css {
  margin: 0px 0px;
  padding: 0 0px 0px;
}
@media screen and (max-width: 767px) {
  #Content13_0 .title-wrapper > .l7oqcl9n2it-editor_css {
    margin: 0px 0px;
    padding: 0px 0px;
  }
}
#Content13_0 .title-wrapper > .l7oqbra0gdh-editor_css {
  padding: 0 0px 0px;
}
@media screen and (max-width: 767px) {
  #Content13_0 .title-wrapper > .l7oqbra0gdh-editor_css {
    padding: 0px 0px;
  }
}
#Content13_1 .l7oomgk71gj-editor_css {
  margin: 0px auto;
}
@media screen and (max-width: 767px) {
  #Content13_1 .l7oomgk71gj-editor_css {
    margin: 0px auto;
    padding: 0 10px;
  }
}
#Content13_2 .l7op1zqj24l-editor_css {
  margin: 0px auto;
}
@media screen and (max-width: 767px) {
  #Content13_2 .l7op1zqj24l-editor_css {
    margin: 0px auto;
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  #Content13_3 .l7ptu7gzbii-editor_css {
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  #Content13_4 .l7ptug6w4hp-editor_css {
    padding: 0 10px;
  }
}
#Content13_4.l7pt2882cl-editor_css {
  background-color: rgba(255, 255, 255, 0);
  background-image: url('');
}
#Content4_0.l7oinn39ytm-editor_css {
  min-height: 0px;
  background-color: #e9e9e9;
}
@media screen and (max-width: 767px) {
  #Content4_0.l7oinn39ytm-editor_css {
    min-height: 200px;
  }
}
#Content13_3.l7or62lg05-editor_css {
  min-height: 0px;
  background-color: #e9e9e9;
  background-image: url('');
}
#Footer1_1 .copyright-wrapper > .home-page > .l7kzuj6a0a-editor_css {
  line-height: 30px;
  color: #fbfbfb;
  height: 70px;
}
#Footer1_1 .copyright-wrapper > .home-page > .copyright:hover {
  color: #fbfbfb;
}

